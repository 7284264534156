<template>
  <div class="wx-img-mat-selector">
    <div class="img-container">
      <div
        v-for="item in data.rows"
        :key="item.id"
        :class="{ 'item-activite': selectedId === item.id }"
        class="img-item"
      >
        <el-image
          class="image"
          referrerpolicy="no-referrer"
          :src="item.url"
          fit="cover"
          @click="select(item)"
        />
        <span>{{ item.mediaName }}</span
        >
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="loadMatList"
        :total="data.total"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "wx-image-mat-selector",
  data() {
    return {
      selectedId: null,
      pageNo: 1,
      data: {},
    };
  },
  methods: {
    select(item) {
      this.selectedId = item.id;
      this.$emit("select", this.selectedId);
    },
    async loadMatList(pageNo) {
      this.data = await this.$http.doApi(
        "fw-wx-material-listPlainMaterial",
        {
          pageNo: pageNo,
          pageSize: 8,
        },
        {
          mediaType: 1,
        }
      );
    },
  },
  created() {
    this.loadMatList(this.pageNo);
  },
};
</script>

<style lang="less">
.wx-img-mat-selector {
  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;

    .img-item {
      width: 172px;
      display: flex;
      flex-direction: column;
      border: @hair-border-ccc;
      box-sizing: border-box;
      margin-bottom: 8px;
      transition: 300ms;
      padding: .3rem;
      padding-bottom: 0;

      &:hover {
        transform: scale(1.03);
        box-shadow: @light-shadow;
      }

      .image {
        width: 100%;
        height: 150px;
        margin-bottom: 8px;
        cursor: pointer;
      }

      span{
        border-top: 1px solid #8d8c8c;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        box-sizing: border-box;
        padding: 0.1rem .5rem
      }
    }
    .item-activite {
      border: solid 1px red;
    }
  }
  .pagination {
    margin-top: 10px;
    text-align: center;
  }
}
</style>